<script>
export default {
  name: 'Bca',
  page: {
    title: 'Εταίροι − BCA'
  }
}
</script>

<template>
  <div>
    <div class="page-header">
      <b-container class="bv-example-row">
          <h1 class="title">Εταίροι − <span class="title-accent">BCA</span></h1>
      </b-container>
    </div>
    <div class="page-body">
      <b-container class="bv-example-row">
        <p class="page-text">Το μεγαλύτερο Κολλέγιο στην Ελλάδα με 45 χρόνια λειτουργίας στους τομείς των Οικονομικών, Κοινωνικών και Ναυτιλιακών σπουδών. Σε συνεργασία με Βρετανικά Πανεπιστήμια –όπως το Plymouth University-προσφέρει είτε σε μορφή franchise είτε ως πιστοποιημένα προπτυχιακά και μεταπτυχιακά προγράμματα σπουδών.
        </p>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use './../../assets/scss/abstracts' as *;

.page-header {
  background-color: $white;
  padding: $s-xl 0 $s-s 0;
}

.title {
  color: $black;
  font-family: "Waiting for the Sunrise", cursive;
  padding: $s-s 0;
}

.title-accent {
 color: $red;
}

.page-body {
  background-color: $alto;
  color: $black;
  padding: $s-xl 0;
}

.page-text {
  line-height: $s-xl;
}
</style>
